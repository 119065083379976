#Preloader {
    --font-size: var(--font-size-s);
    --color: var(--white);
    --bg: var(--pink);

    @media (max-width: $smartphone) {
        --font-size: 4vw;
    }
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include z-index($z-index-loader);
    background-color: var(--bg);

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        height: auto;
        width: rem(350);

        path {
            fill: var(--white);
        }
    }

    @media (max-width: $smartphone) {
        svg {
            width: rem(170);
        }
    }
}
