.footer {
    --font-size: var(--font-size-base);
    --padding-v-bottom: var(--padding-l);
    --padding-v-top: var(--padding-xxl);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --size-logo-1: #{rem(20)};
        --size-logo-2: #{rem(36)};
        --size-logo-cuchillo: #{rem(52)};
        --padding-v-bottom: var(--padding-m);
        --padding-v-top: var(--padding-xl);
        --padding-h: var(--padding-xs);
    }

    @media (min-width: $smartphone) {
        --size-logo-1: #{rem(30)};
        --size-logo-2: #{rem(54)};
        --size-logo-cuchillo: #{rem(72)};
        --padding: var(--padding-xxs);
    }
}

.footer {
    @include font-text();

    position: relative;
    padding: var(--padding-v-top) 0 var(--padding-v-bottom);
    
    a {
        @include basic-a();
        color: var(--primary-color);
    }

    .logo {
        margin-bottom: var(--padding-m-em);
        padding: 0 var(--padding-h);

        // .icon {
        //     width: 100%;
        //     height: auto;
        // }
    }

    .legals {
        margin-top: var(--padding-xxs);
        
        > *:not(:last-child) {
            margin-right: var(--padding-xxxs);
        }

        button {
            padding: 0;
            background-color: transparent;
            color: currentColor;
        }
    }

    .content {
        padding: 0 var(--padding-h);
    }

    svg {
        fill: var(--primary-color);
        display: block;
        width: 100%;
    }

    &__logos {
        display: flex;
        gap: var(--padding-xxs);
        align-items: flex-start;

        .logo-cuchillo {
            margin-right: 1.4em;
            flex: 0 0 auto;
            position: relative;
            display: flex;
            align-items: flex-end;
            gap: .41em;
            @include font-sans();
            font-size: var(--font-size);

            span {
                display: block;
                position: relative;
                top: .2em;
            }

            svg {
                position: relative;
                fill: currentColor;
                width: var(--size-logo-cuchillo);
                height: auto;
                top: -.1em;

                @media (max-width: $smartphone) {
                    top: -.1em;
                }
            }

            sup {
                position: absolute;
                top: .45em;
                right: -1.2em;
            }
        }

        .logo-kd01 {
            > svg {
                display: block;
                height: var(--size-logo-1);
                width: auto;
                fill: currentColor;
            }
        }

        .logo-kd02 {
            > svg {
                display: block;
                height: var(--size-logo-2);
                width: auto;
                fill: currentColor;
            }
        }
    }

    @media (max-width: $smartphone) {
        &__logos {
            justify-content: flex-start;
        }

        .content {
            display: flex;
            flex-direction: column;
                        
            > *:not(:last-child) {
                margin-bottom: var(--padding-xs);
            }
        }
    }
    
    @media (min-width: $smartphone) {
        &__logos {
            justify-content: flex-end;
        }
        
        .logo {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: rem(5);
            height: rem(80);
            width: 100%;
            
            .icon--footer-lines {
                width: rem(374);
                height: rem(80);
            }
            
            .icon--footer-1 {
                width: rem(455);
                height: rem(80);
            }
            
            .icon--footer-2 {
                width: rem(18);
                height: rem(80);
            }
            
            .icon--footer-3 {
                width: rem(155);
                height: rem(80);
            }

            .__logo {
                transform-origin: center left;
            }
        }

        &__text {
            > div {
                width: rem(400);
            }
        }

        &__address {
            > * {
                display: block;
                width: rem(190  );
            }
        }
        
        .content {
            display: flex;
            justify-content: space-between;
        }
    }
}
